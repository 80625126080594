@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

body {
    background-color: #000;
}

* {
    font-family: 'figtree', sans-serif;
}

.headline-text {
    margin-left: 18px;
    font-weight: bold;
}

button a {
    color: unset;
    text-decoration: none;
}

body {
    background-color: #fff !important;
}

.textcenter {
    text-align: center;
}

span.sm-highlight {
    background: #ef8f23;
    width: fit-content;
    color: #000;
    padding: 2px 10px;
    position: absolute;
    top: 27%;
    left: 20px;
}

.bg-img-join span.sm-highlight {
    top: 33%;
    left: 18px;
}

.banner {
    position: relative;
}

.banner {
    position: relative;
}

.bg-img {
    background: url('../public/right_career_home.webp') right bottom no-repeat;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    animation: shrink 12s infinite;
    position: relative;
}


/* Header Navigation */

.navbar-nav .dropdown-menu {
    overflow: hidden;
}

.dropdown-toggle.show {
    transition: all 2s linear;
}

@keyframes shrink {
    0% {
        background-size: 100%;
        opacity: 0.5;
    }

    50% {
        background-size: 110%;
        opacity: 1;
    }

    100% {
        background-size: 100%;
        opacity: 1;
    }
}

.bg-img h3 {
    justify-self: center;
}


/* Media query */

@media (max-width: 320px) {
    .stage:after {
        left: 39%;
    }

    .mapBg {
        background-size: contain;
        height: 230px !important;
        background-position: bottom;
        background-repeat: no-repeat;
        background-color: #f3f3f3;
    }
}

@media (min-width: 330px) and (max-width: 420px) {
    .stage:after {
        left: 33.5% !important;
    }

    .mapBg {
        height: 255px !important;
    }
}

@media (min-width: 421px) and (max-width: 430px) {
    .stage:after {
        left: 27% !important;
    }
}

@media (max-width: 760px) {
    .bg-img {
        background: url('./img/home_banner_mob.jpg');
        background-size: cover !important;
        animation: none;
        height: 400px;
        justify-content: space-between;
        padding-top: 24px;
    }

    .bg-img-about {
        background-image: url('./img/abt_banner_mob.jpg') !important;
        background-size: cover;
        animation: none !important;
        height: 400px !important;
        justify-content: space-between;
        padding-top: 0;
        transition: none !important
    }

    .vision h4 {
        font-size: 25px !important;
    }

    h3.sectTitle:after {
        width: 30% !important;
    }

    .lftSect p {
        font-size: 20px;
        line-height: 1.4;
        color: #e59132;
    }

    .navbar-brand img {
        width: 200px;
    }

    .videoContent {
        position: unset !important;
        transform: unset !important
    }

    .innerContent {
        background-color: #90806e !important;
        color: #fff;
        opacity: 1;
    }

    .testCards .card.px-5 {
        padding: 15px !important;
    }

    .clientInfo p {
        font-size: 18px !important;
    }

    .service-sect .row {
        padding: 0 !important;
    }

    .service-sect .boxes {
        max-width: 100%;
    }

    .slider {
        max-width: unset !important;
    }

    /* header nav mobile */

    .socialIcons a i {
        font-size: 25px;
        color: #fff;
        margin: 0 0 0 28px !important;
    }

    .elipses .rounder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px !important;
        height: 35px !important;
        border: none !important;
    }

    section.indstats video {
        display: none;
    }

    .clients.py-5 .text-center img {
        width: 190px !important;
    }

    .socialIcons a:nth-child(1) i {
        margin: 0 !important;
    }

    .words {
        margin-bottom: 0;
    }

    .clientInfo {
        min-height: 95px;
    }

    .connectForm .container {
        margin: 0 auto;
        max-width: 923px;
        padding: 0 4px !important;
    }

    .dropwdownBox {
        background-color: unset;
    }

    .mobMenu .dropwdownBox a {
        color: #ffffff !important;
    }

    .servicBtn {
        display: inline-block !important;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }

    .cta-btn .servicBtn {
        left: 0 !important;
        transform: unset !important;
    }

    span.sm-highlight {
        top: 47% !important;
    }

    .bdcrumb {
        padding: 5px 19px !important;
        position: absolute;
        top: 0;
    }

    .banner {
        justify-content: flex-end !important;
    }

    .bg-img {
        justify-content: flex-end !important;
    }

    a.custom-link {
        color: unset !important;
    }

    .footer .col-md-3.mt-5 {
        margin-top: 15px !important;
    }

    .footer .col-md-4.mt-5 {
        margin-top: 5px !important;
    }

    .footer .socialMedia {
        margin-top: 20px !important;
    }

    .footer .informative img {
        width: 93%;
    }

    .footer .container {
        padding: 16px 15px !important;
    }

    .copyright {
        display: flex;
        flex-wrap: wrap-reverse;
    }

    .copyright .internlinks {
        padding: 0 !important;
    }

    .footer a {
        padding: 0 2px;
    }

    .copyright.my-2.container {
        padding: 16px 9px !important;
    }

    .footer a {
        padding: 0 2px !important;
    }

    .copyright {
        justify-content: center !important;
        flex-wrap: wrap;
    }

    .copyright p:nth-child(1), p:nth-child(2){
        margin-bottom: 10px !important;
    }

    .copyright .internlinks {
        text-align: center;
        margin-bottom: 10px !important;
        padding: 0 20px;
    }

    /* mob about overview */

    .mapBg {
        background: url('./img/map_mob.webp');
        background-size: contain;
        height: 300px;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-color: #f3f3f3;
    }

    .mapBg h3.sectTitle {
        padding-top: 15px;
    }

    .visionContent {
        padding: 20px !important;
    }

    .cardContainer {
        display: block !important;
    }

    .cardContainer .card:nth-child(1) {
        margin-top: 0 !important;
    }

    .cardContainer .card {
        margin-top: 25px !important;
    }

    .modal-dialog-centered {
        margin: 10px 15px !important;
    }

    .popup .col-md-8 p {
        margin-top: 15px;
    }

    .popup .col-md-8.px-4 {
        padding-right: 12px !important;
        padding-left: 12px !important;
    }

    .visionContent h3:after {
        width: 60% !important;
    }

    /* Join Us section */

    .valuesSect {
        display: block !important;
    }

    .valuesSect .boxes {
        padding: 10px 0 !important;
    }

    .values .container p:nth-child(2) {
        margin: auto !important;
    }

    /* join us banner */
    .bg-img-join {
        background-image: url('./img/joinus_banner_mob.jpg') !important;
        transition: none;
        animation: none;
    }

    .joinSect .content {
        color: #000;
        font-size: 24px !important;
    }

    h3.sectTitle {
        font-weight: 700;
        font-size: 25px !important;
    }

    .rghtSect p {
        font-size: 16px !important;
    }

    .vision p {
        width: 100% !important;
        margin: 0 auto;
    }

    .formContainer h3.sectTitle br {
        display: none;
    }

    .formContainer h3.sectTitle {
        font-size: 20px !important;
    }

    .joinUs .inpt {
        width: 100% !important;
    }

    .joinUs .fileUpload {
        position: relative;
        padding: 9px;
        text-align: left;
    }

    .joinUs .fileUpload {
        position: relative;
        padding: 9px !important;
        text-align: left;
    }

    .fileUpload span {
        position: unset !important;
        text-align: left !important;
    }

    .fileUpload span .file {
        padding-left: 0 !important;
        width: 100%;
    }

    .footer a {
        color: #545454 !important;
        text-decoration: none !important;
        padding: 0 3px 0 0;
    }

    .cardContainer .card img {
        border-top-right-radius: 0.375rem;
        border-top-left-radius: 0.375rem;
    }

    .services_bg_auto span.sm-highlight {
        top: 52% !important;
    }

    .services_bg_bank span.sm-highlight {
        top: 60% !important;
    }

    .services_bg_ener span.sm-highlight {
        top: 60% !important;
    }

    .finance span.sm-highlight {
        top: 43% !important;
    }

    .services_bg_heal span.sm-highlight {
        top: 60% !important;
    }

    .services_bg_hosp span.sm-highlight {
        top: 60% !important;
        margin-right: 30px;
    }

    .services_bg_hosp h1 br {
        display: none;
    }

    .services_bg_ind span.sm-highlight {
        top: 51% !important;
    }

    .services_bg_info span.sm-highlight {
        top: 60% !important;
    }

    .services_bg_life span.sm-highlight {
        top: 60% !important;
    }

    .services_bg_logi span.sm-highlight {
        top: 60% !important;
    }

    .services_bg_media span.sm-highlight {
        top: 60% !important;
    }

    .services_bg_media h1 br {
        display: none;
    }

    /* Join us end */
    /* Connect with us */

    .bg-img-connect {
        background-image: url('./img/get_in_touch_banner_mob.jpg') !important;
    }

    .presence {
        display: block !important;
    }

    .presence .block2 {
        margin-top: 10px;
        margin-left: 0 !important;
    }

    .userSect .submitBtn {
        margin: unset !important;
    }

    .connectForm .inptBlock {
        width: 100%;
        display: block;
        margin-bottom: 8px;
    }

    .btndiv{
        display: block;
        width: 100%;
        margin-top: 10px;
    }

    .connectForm .inptBlock .inpt,
    .PhoneInput input {
        width: 100% !important;
        padding: 5px;
    }

    .connectForm .container .upperFrmSect {
        display: block !important;
        margin: 0 auto !important;
    }

    .connectForm .inptBlock {
        display: block !important;
        width: 95% !important;
        margin: 10px auto !important;
    }

    .PhoneInput {
        width: 100%;
    }

    .employee {
        display: block !important;
        margin: 0px 20px !important;
    }

    .fileUploadBlck {
        display: block;
        width: 100%;
    }

    .connectForm .userSect .inptBlock {
        display: block !important;
        width: 86% !important;
        margin: 10px auto !important;
    }

    .staffing.py-5 .sm-title {
        position: static !important;
    }

    .mobMenu .dropwdownBox {
        position: relative;
        top: 0;
        width: 90% !important;
        min-width: unset;
    }

    .dropwdownBox a {
        text-decoration: none;
        color: #000;
        display: block;
        padding: 6px 10px;
    }

    .userSect,
    .employee {
        padding-top: 0px !important;
    }

    .userSect .text-center.pt-3 {
        padding-top: 0 !important;
    }

    .inptBlock.col-md-5.mt-4 {
        display: none !important;
    }

    .employee {
        margin-bottom: 15px !important;
        width: 85%;
    }

    .p-5.w80 {
        padding: 3rem 0.25rem !important;
    }

    .PhoneInput {
        width: 100% !important;
    }

    .connectForm h3 {
        padding-bottom: 25px !important;
    }

    .dropwdownBox a {
        padding: 6px 10px !important;
    }

    .dropwdownBox {
        background-color: unset !important;
    }

    .mobMenu .dropwdownBox a {
        color: #ffffff !important;
        max-width: 100%;
        line-height: 1.2;
    }

    .partnership .partners img {
        padding: 8px 10px !important;
        width: 50% !important;
    }

    .bg-img-join span.sm-highlight {
        top: 69% !important;
        left: 18px;
    }

    .bg-img-connect span.sm-highlight {
        top: 52% !important;
        left: 18px;
    }

    span.sm-highlight {
        top: 53% !important;
    }

    .headings h2 {
        font-size: 26px;
    }

    .imgrows img {
        padding: 0;
        width: 44%;
    }

    .elipses {
        flex-direction: column;
        align-items: center;
    }

    .elipses hr {
        display: none;
    }

    .upperpart {
        text-align: center;
    }

    .elipses {
        display: flex;
        justify-content: space-around;
        padding: 15px 20px;
    }

    .elipse {
        width: fit-content !important;
        margin: 10px 0;
    }

    .d7r .button {
        font-size: 22px !important;
    }

    .headings h2 {
        font-size: 24px !important;
        margin: 0 10px;
    }

    h2.industrial.mytext br {
        display: none;
    }

    .indcards p {
        margin-bottom: 0;
        font-size: 16px !important;
    }

    .indcards h5 {
        font-weight: bold;
        font-size: 18px !important;
        margin-bottom: 2px;
    }

    .staff {
        width: 98% !important;
    }

    .indcards .ps-4 {
        padding-left: 0.6rem !important;
    }

    .industry.headings {
        width: 90% !important;
        margin: auto;
    }

    .srvcGrntee .visionContent h2 {
        font-size: 27px !important;
    }

    .hiringBg .visionContent h2 {
        font-size: 27px !important;
    }

    .bottompart {
        /* text-align: center; */
        margin: 0 auto !important;
        position: relative;
    }

    .elipses .rounder {
        position: static !important;
        margin: 10px auto;
    }

    .elipse {
        width: fit-content !important;
    }

    .elipses .texts {
        position: static !important;
    }

    .lftSect p {
        padding-top: 19px !important;
        font-size: 22px !important;
        line-height: 1.3 !important;
        font-weight: 500;
    }

    .d7r .button {
        margin: 0 auto !important;
    }

    .needs.px-5 {
        padding: 20px 10px !important;
    }

    .accrdn .accordion-body {
        text-align: left !important;
        padding: 8px 1px !important;
        color: #000;
    }

    .sect.sect2.mt-5 {
        margin-top: 15px !important;
    }

    .needs .sect h3 {
        line-height: 1.3;
        font-size: 18px;
    }

    .needs p {
        margin-bottom: 0;
        color: #000;
        margin: 7px 0;
    }

    .needs .sect h3 {
        margin-top: 12px !important;
        line-height: 1.3;
    }

    /* accordian */
    .accrdn .accordion-button {
        font-size: 20px !important;
    }

    .srvcType .container .col-md-6 {
        text-align: center;
    }

    /* Ind last sect mob */

    .indstats {
        height: 500px !important;
    }

    .visionBg {
        width: 100%;
        height: 400px !important;
    }

    .indstats .visionBg {
        width: 100%;
        height: 500px !important;
    }

    .rghtSect .lady {
        width: 100%;
        position: relative;
        left: 0 !important;
    }

    .story h3 {
        margin-bottom: 0 !important;
    }

    .indRows {
        display: flex !important;
        margin: 22px 0 !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
    }

    span.boldText {
        font-weight: bold;
        font-size: 33px !important;
    }

    input[type=file]::file-selector-button {
        background-color: #e1953a;
        color: #fff;
        border: none;
        padding: 3px 8px;
    }

    .needs .sect h3 {
        line-height: 1.3 !important;
        font-size: 23px;
    }

    h3.stgs.sectTitle.aos-init.aos-animate {
        margin: 0 12px;
    }

    .indRows p {
        margin: 20px auto !important;
    }

    .line {
        display: none;
    }

    /* service vertical line */

    .stages {
        height: auto;
    }

    .stage:after {
        content: "";
        width: 0px;
        display: inline-block;
        border-width: 1px;
        border-style: dashed;
        height: 70px;
        border-color: #fff;
        opacity: 1;
        position: relative;
        left: 39%;
        transform: translateX(-50%);
        bottom: 0px;
        top: 0;
    }

    p.stgText.aos-init.aos-animate br {
        display: none;
    }

    .stgCircle {
        left: 0 !important;
        bottom: 4px !important;
        margin-bottom: 0 !important;
    }

    .stages {
        background: unset !important;
        height: auto !important;
        display: block !important;
        margin: 27px auto !important;
        padding: 0 29px !important;
    }

    p.stgText {
        position: absolute !important;
        left: 88% !important;
        top: 0;
        width: 200px !important;
        text-align: left !important;
        padding-left: 10px;
    }

    .stage:nth-child(10):after {
        display: none;
    }

    .stgBtn {
        margin: 56px 20px 0 !important;
        padding: 10px 24px !important;
        color: #000;
        font-weight: bold;
        font-size: 22px;
    }
}

.navbar1 {
    align-items: flex-end !important;
}

.nav-menu {
    font-family: 'figtree', sans-serif;
    --bs-nav-link-color: #545454;
    --bs-nav-link-hover-color: #545454;
    --bs-navbar-active-color: #545454
}

a.navbar-brand {
    padding: 0;
}

h3.sectTitle {
    font-weight: 700;
    font-size: 35px;
    color: #000;
}

h3.sectTitle:after {
    content: "";
    display: block;
    width: 10%;
    height: 4px;
    background-color: #000;
    position: relative;
    top: 5px;
    left: 50%;
    transform: translate(-50%);
}

h3.sectTitle.stgs:after {
    width: 20%
}

.service-sect .boxes:hover img {
    transform: rotate(360deg);
    transition: all 1s;
}

.service-sect {
    position: relative;
}

.videoBg {
    width: 100%;
    height: 140vh;
    position: relative;
    object-fit: cover;
    z-index: -99;
    opacity: 0.8;
}

.videoContent {
    z-index: 9;
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.innerContent {
    background-color: #00000059;
    color: #fff;
}

.innerContent .row {
    justify-content: center;
}


.innerContent h3 {
    color: #fff;
}

.innerContent .sectTitle:after {
    background-color: #ffffff !important;
}

.navbar-nav .nav-item {
    padding: 0 15px;
}

.navbar-nav .nav-link {
    padding: 0 15px !important;
}

/* .testCards .card{
    width: 48% !important;
} */

.cardImg {
    max-width: max-content;
}

.custom-typography {
    animation: upNdown 1.5s infinite linear;
    position: relative;
}

@keyframes upNdown {
    0% {
        top: 0;
    }

    50% {
        top: 15px;
    }

    100% {
        top: 0;
    }
}

.over-text {
    color: #000;
    max-width: 980px;
    text-align: center;
    font-family: 'figtree', sans-serif;
    font-size: 25px;
    font-weight: 500;
}


.slick-slide {
    margin: 0 27px;
}

.container-opp-direct {
    margin-top: 40px !important;
}

.sbmt-btn {
    justify-self: center;
    position: relative;
    z-index: 1;
}

.sbmt-btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #56a8da;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.sbmt-btn:hover {
    color: #fff;
}

.sbmt-btn:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.custom-typography {
    font-size: 50px;
    color: #fff;
}

.custom {
    justify-self: center;
}

.sect-overview {
    position: relative;
}

.sect-overview:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -18px;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 18px solid #db933b;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
}

.boxes img {
    background: #eb932f;
    border-radius: 50%;
    padding: 20px;
    width: 110px;
    height: 110px;
}

.boxes h3 {
    padding-top: 10px;
    font-size: 23px;
}

.servicBtn {
    padding: 10px 22px;
    background-color: #000;
    border: none;
    color: #fff;
    position: relative;
    z-index: 999;
    display: flex;
    justify-self: center;
}

.servicBtn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #56a8da;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}

.servicBtn:hover:before {
    transform: scaleX(1);
}

/* Testimonial section */

.testimonialSect {
    background-image: linear-gradient(to bottom, #e4dfda 50%, #fff);
}

.testCards .card {
    border: none;
    border-radius: 0;
    box-shadow: -2px 2px 11px -3px #00000042;
}

.testCards .card .words {
    padding: 15px 0;
    font-weight: 500;
    color: #000020;
    font-size: 17px;
    min-height: 172px;
    line-height: 1.4;
}

.testCards .cardImg {
    height: 62px;
}

.clientInfo {
    margin-top: 20px;
}

.clientInfo h4 {
    color: #e8912e;
    font-weight: 700;
    margin-bottom: 0;
}

.clientInfo p {
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

/* Footer Section */

.footer {
    background-color: #e5e5e5;
}

.slick-slide img {
    width: 150px !important;
    height: 150px;
}

.location {
    display: flex
}

.location * {
    color: #000 !important;
}

.location .icons {
    color: #585858;
}

.location span {
    font-size: 14px;
}

.links li {
    font-size: 14px;
}

.socialIcon {
    width: 30px !important;
    height: 30px !important;
    margin: 0 5px;
}

.socialMedia p {
    font-size: 14px;
}

.socialmediaIcon {
    width: 25px;
    height: 25px;
    margin: 0 3px;
    cursor: pointer;
}

.copyright {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding-top: 6px;
}

.footer a {
    color: #545454 !important;
    text-decoration: none !important;
}

.footer a:hover {
    color: #0d6efd !important;
    text-decoration: underline !important;
}

.copyright.my-2.container p {
    font-size: 13px;
    color: #000;
}

.dropdown-item {
    --bs-dropdown-link-active-bg: #20aae1;
}

.customNavigation {
    color: unset;
    text-decoration: none;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
}

.popup .imgSect img {
    max-width: 100%;
}

.popup .imgSect h4.title {
    color: #00a1e0;
    font-weight: 600;
}

.popup .imgSect h4 {
    font-size: 22px;
}

.popup .imgSect h4 {}