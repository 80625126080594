.services p {
    color: #000;
    font-weight: 500;
}

.services_bg {
    background-image: url('./industries_imgs/financial_banners.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    animation: shrink 12s linear infinite;
}

.headings h2 {
    font-size: 40px;
}

.mytext {
    background-image: linear-gradient(to right, #0086bb 50%, #2ea730);
    color: transparent;
    background-clip: text;
}

.mytext2 {
    background-image: linear-gradient(to left, #2ea730 42%, #0086bb);
    color: transparent;
    background-clip: text;
}

.imgrows img {
    padding: 0 12px;
}

/* service Gurantee */

.srvcGrntee {
    background-image: url('./img/rc_service_guarantee.webp');
    background-position: center;
    height: 400px;
    display: flex;
    align-items: center;
}

.d7r .button {
    background-color: #eb932f;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding: 15px;
    border-radius: 50px;
    margin: 0;
    width: 95%;
}

hr {
    border: none;
    border-left: 2px #000;
    border-style: dashed;
    color: #fff;
    height: 106px;
    width: 1px;
    position: relative;
    left: 33%;
    top: 0;
    margin: 0;
    opacity: 1;
}

.elipse {
    width: 150px;
}

.d7r .container {
    position: relative;
}

.elipses {
    display: flex;
    justify-content: space-around;
}

.elipses img {
    width: 100px;
    height: 100px;
}

.elipses .rs7 {
    position: unset !important;
}

.elipses .rounder {
    background-color: #acacac;
    left: 26%;
    position: relative;
    width: fit-content;
    text-align: center;
    width: 24px;
    height: 24px;
    border: 1px solid #000;
    border-radius: 50px;
}

.texts {
    position: relative;
    left: -25px;
}

.texts *{
    color: #000;
}

.texts p {
    font-size: 14px;
}

.process {
    background-image: url('./img/right_career_services.webp');
}

.sect4 img{
    width: 85%;
}

.stages {
    background: url('./img/services_background.png') no-repeat;
    background-size: contain;
    display: flex;
    flex-wrap: wrap;
    height: 400px;
    margin: 95px auto;
}

.stage {
    width: 20%;
    position: relative;
    bottom: -18px;
}

.btmpart {
    top: 116px;
}

.stgCircle {
    width: 40px;
    height: 40px;
    background-color: #eb932f;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 52px;
}

p.stgText {
    color: #000;
    font-weight: 600;
    text-align: center;
    line-height: 1.1;
    font-size: 20px;
    position: relative;
    left: -40px;
}

.stages .dwn .stgText {
    position: relative;
    top: 0;
}

.stages .up .stgText {
    position: relative;
    bottom: 108px;
}

.stgBtn {
    text-align: center;
    background-color: #eb932f;
    width: fit-content;
    margin: 0 auto;
    padding: 10px 24px;
    color: #000;
    font-weight: bold;
    font-size: 22px;
}
.needs {
    background-image: linear-gradient(to bottom, #ffffff 50%, #f4f4f4);
}

.needs p{
    margin-bottom: 0;
    color: #000;
}

.needs .sect h3{
    line-height: 1;
}

.sect2 p span:nth-child(2) {
    padding-left: 10px;
    font-size: 15.5px;
}

.sect2 p span:nth-child(1) {
    font-weight: bold;
}

.nobold{
    font-weight: 400 !important;
}

.logos h3 {
    color: #000;
    margin-bottom: 0px;
}

.logos p{
    color: #000;
}

.benefits .logos img {
    width: 120px;
    height: 120px;
}

.hiringBg{
    /* background: url('./img/careerBg.webp') center/cover no-repeat; */
    height: 300px;
    display: flex;
    align-items: center;
}

.hiringBg h2{
    color: #fff;
    margin: auto;
    text-align: center;
    width: 80%;
    font-weight: 700;
    font-size: 45px;
}

.srvcType img {
    width: 90%;
}

/* accordion */
.accrdn .accordion-button::after{
    background-image: url("./img/plus-svgrepo-com.png");
    background-size: 80%;
    position: absolute;
    left: 0;
}

.accrdn .accordion-button:not(.collapsed)::after {
    background-image: url("./img/minus-svgrepo-com.png");
    background-size: 120%;
}

.accrdn .accordion-button:not(.collapsed) {
    box-shadow: unset;
}


.accrdn .accordion-item {
    border: none;
    border-bottom: 1px solid #000000 !important;
    border-radius: 0 !important;
}

.accrdn .accordion-button{
    padding-left: 25px;
    font-size: 25px;
}

.accrdn .accordion-button:not(.collapsed) {
    box-shadow: unset;
    padding-bottom: 0;
    color: #ef8f22;
    font-weight: 600;
}

.accrdn .accordion-body{
    padding: 8px 20px;
    color: #000;
}

.logos .col-md-3:hover img{
    
}