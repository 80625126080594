.bg-img-about {
    background-image: url('../public/aboutus_overview.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    animation: shrink 12s linear infinite;
}

.bg-img-join {
    background-image: url('./img/right_career_join_us.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    animation: shrink 12s linear infinite;
}

.bg-img-connect {
    background-image: url('./img/get_in_touch_banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;
    animation: shrink 12s linear infinite;
}

.bdcrumb {
    color: #fff;
    padding: 5px 11px;
    font-size: 14px;
}

.bdcrumb a {
    text-decoration: none;
    color: #fff;
}

.bdcrumb a:hover {
    text-decoration: underline;
}

/* background-image: linear-gradient(rgba(0, 0, 255, 0.5), rgba(255, 255, 0, 0.5)),
                  url("../../media/examples/lizard.png"); */

@keyframes shrink {
    0% {
        background-size: 100%;
        opacity: 0.5;
    }

    50% {
        background-size: 105%;
        opacity: 1;
    }

    100% {
        background-size: 100%;
        opacity: 1;
    }
}

.lftSect p {
    font-size: 25px;
    line-height: 1.4;
    color: #e59132;
}

.rghtSect p {
    color: #000000;
    font-weight: 500;
    font-size: 17px;
}

/* .vision {
    display: block;
    background-image: url('../public/our-vision_about_us.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
    color: #fff;
    text-align: center;
    justify-content: center;
} */

.vision {
    height: 400px;
}

.visionBg {
    width: 100%;
    height: 400px;
    position: absolute;
    object-fit: cover;
    z-index: -99;
    opacity: 1;
}

.visionContent {
    z-index: 9;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 89px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-image: linear-gradient(to bottom, #0086bbd1 30%, #538b34d1);
}

.visionContent h3:after{
    width: 60%;
}
.vision * {
    color: #fff !important;
}

.vision p {
    width: 74%;
    margin: 0 auto
}

.vision .sectTitle:after {
    background-color: #fff !important;
}

.cardContainer {
    display: flex;
    justify-content: space-around;
}

.cardContainer .card {
    margin: 0 15px;
    text-align: center;
    background-color: #ede8e999
}

.cardContainer .card h4 {
    padding-top: 15px;
    color: #7c6e71;
    font-weight: 700;
    margin-bottom: 0;
}

.cardContainer .card h6 {
    font-size: 18px;
    color: #7c6e71;
}

.ctaPop {
    color: #e67f09;
    cursor: pointer;
}

.ctaPopSvg {
    font-size: 20px;
    position: relative;
    bottom: 2px;
}

.vision h4 {
    font-size: 35px;
}

.partnership {
    background-color: #f2f2f2;
}

.partnership h2 {
    font-weight: bold;
    color: #000;
}

.partnership .container {
    max-width: 1171px;
}

.partnership .partners img {
    padding: 8px 20px;
}


/* Map Section */

.map {
    background-color: #f3f3f3;
    min-height: 700px;
}

.map .contain {
    position: relative;
    display: inline-block;
    max-width: 100%;
    width: fit-content;
    text-align: center !important;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%);
}

.pointer {
    display: inline-block;
    position: absolute;
    right: 50%;
    top: 50%;
}

.pointer.usa {
    right: 81%;
}

.pointer.europe {
    right: 49%;
    top: 13%;
}


.pointer.uae {
    right: 34%;
    top: 31%;
}

.pointer.india {
    right: 27.5%;
    top: 38%;
}

.pointer.newzealand {
    right: 11%;
    top: 82%;
}

@keyframes bounce {
    0% {
        transform: translateY(-100%);
    }

    100% {
        /* top: 38%; */
    }
}

.map .contain .icons {
    color: #7fbb00;
    font-size: 42px;
}

.map .contain .india .icons {
    color: #1fa9e1;
    font-size: 42px;
}

.mapdetails {
    justify-content: center;
    position: relative;
    bottom: 45px;
}

.mapdetails .icons {
    font-size: 30px;
    color: #20a9e1;
}

.mapdetails .greenIcon {
    color: #7dc01b !important;
}