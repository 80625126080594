.slick-slide {
    margin: 0 27px;
}

.slick-slide img{
    width: 150px !important;
    height: 150px;
}

.cookie-modal {
    background-color: #f8f9fa !important; /* Soft light gray for a modern look */
    color: #333 !important; /* Dark gray text for readability */
    max-width: 320px;
    border: 1px solid #ccc !important; /* Subtle border */
    font-size: 14px;
    z-index: 1050 !important; /* Keeps it above other elements */
    padding: 15px !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15) !important; /* Softer shadow for depth */
    border-radius: 10px !important; /* Rounded corners for a sleek feel */
}

/* Style the button for a premium, modern look */
.cookie-modal button {
    background-color: #007bff !important; /* Soft blue for a clean, modern touch */
    color: white !important;
    border: none !important;
    font-size: 14px;
    font-weight: 600 !important;
    padding: 12px 30px !important;
    border-radius: 8px !important;
    transition: all 0.3s ease-in-out;
    margin-right: 10px;
    margin-top: 6px;
}

/* Button hover effect for a smooth interaction */
.cookie-modal button:hover {
    background-color: #0056b3 !important; /* Darker blue on hover */
    color: white !important;
}

  
/* Responsive styles start here */
@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        --bs-modal-width: 1000px !important;
    }
}

/* mobile */

@media (max-width: 767px) {
    .p-5.sect-overview{
        padding: 3rem 0 !important;
    }

    .over-text{
        font-size: 18px;
    }
}

@media (min-width: 1260px) {
    .cs1{
        width: 30% !important;
    }

    .cs2{
        width: 45% !important;
    }

    .cs3{
        width: 25% !important;
    }

    .br_right {
        border-right: 1px solid #545454;
        margin-right: 36px;
        margin-top: 34px;
    }

    .br_next{
        margin-top: 31px;
    }

    .br_title{
        float: right;
    }
}

.spinner-icon {
    margin-left: 10px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}