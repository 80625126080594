.menu {
    display: flex;
}

@media (min-width: 768px) {
    .menu {
        display: flex;
        align-items: flex-end;
    }
}

.azucane_logo img{
    width: 56px;
    height: auto;
}

.menu .menuItem {
    display: block;
    color: #545454;
    cursor: pointer;
    position: relative;
    margin-left: 40px;
}

.menuItem a {
    text-decoration: none;
    color: #545454;
}

.menuItem:hover .dropdownIcon {
    transform: rotate(180deg);
}

.menuItem:hover .dropwdownBox {
    height: 108px;
}

.menuItem:hover .bgdd {
    height: 25rem;
}

.menuItem:hover:after {
    border-bottom: 4px solid rgb(230, 131, 0);
    content: "";
    display: inline-block;
    height: 24px;
    left: 0px;
    position: absolute;
    top: 5px;
    width: 100%;
}

.newHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 5px;
}

.dropwdownBox {
    overflow: hidden;
    height: 0;
    padding: 0;
    position: absolute;
    left: 0;
    top: 28px;
    background-color: #f9f9f9;
    min-width: 205px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.6s ease-in;
    z-index: 99;
}

.dropwdownBox a {
    text-decoration: none;
    color: #000;
    display: block;
    padding: 6px 15px;
}

.dropwdownBox a:hover {
    background-color: #56a4ec;
    color: #fff;
}

.mobHeader {
    align-items: center;
    position: relative;
    height: 100%;
}

.mobHeader img {
    width: 100%;
}

.mobHeader .menuIcon {
    font-size: 25px;
}

.mobMenu {
    flex-direction: column;
    position: fixed;
    /* right: -13px; */
    right: -100%;
    top: 0;
    height: 100vh;
    z-index: 99999;
    width: 189px;
    background: #000;
    padding-top: 100px;
    overflow-y: auto;
    padding-left: 30px;
    transition: all 0.5s linear;
}

.menuIcon.close {
    color: #fff;
    position: fixed;
    z-index: 99999999;
    right: 11px;
}

.menuIcon.close~.mobMenu {
    right: -13px;
    transition: all 0.5s linear;
}

.mobMenu .menuItem {
    padding: 10px 0;
    color: #fff;
    margin-left: 0;
}

.mobMenu .menuItem a {
    color: #fff !important;
}

.mobMenu .dropwdownBox {
    position: relative;
    top: 0;
    width: 88%;
    min-width: unset;
}

.mobMenu .menuItem.active .bgdd {
    height: 28.5rem !important;
}

.mobMenu .dropwdownBox a {
    color: #000 !important;
    max-width: 100%;
    line-height: 1.2;
}

.imgUrl {
    max-width: 100%;
    width: 230px;
}

.mobMenu a {
    color: #000 !important;
}

.mobMenu .dropDown .dropwdownBox {
    height: 0;
}

.mobMenu .menuItem .dropdownIcon {
    transform: rotate(0deg);
}

.mobMenu .menuItem.active .dropdownIcon {
    transform: rotate(180deg);
}

.mobMenu .dropDown.active .dropwdownBox {
    border-bottom: 4px solid rgb(230, 131, 0);
    content: "";
    display: inline-block;
    height: 24px;
    left: 0px;
    position: absolute;
    top: 5px;
    width: 100%;
}

.mobMenu .menuItem:hover:after {
    content: unset;
}

@media (max-width: 760px) {
    .mobMenu .dropwdownBox a {
        color: #ffffff !important;
    }

    .menuItem:hover .dropwdownBox {
        height: 100px !important;
    }

    .mobMenu .menuItem.active .smlbox {
        height: 100px !important;
    }

    .mobMenu .dropDown .dropwdownBox {
        height: 0 !important;
    }

    .mobMenu .policydwn .dropwdownBox {
        height: 0 !important;
    }

    .plcy.active .policyDD {
        height: 20rem !important;
    }
    
    .policyDD a:hover{
        background-color: unset !important;
    }
    
    .mobMenu .menuItem.active .bgdd {
        height: 24.5rem !important;
    }

    .mobMenu {
        width: 247px;
        padding-top: 60px;
        padding-left: 15px;
    }

    .azucane_logo{
        color: #fff;
    }

    .azucane_logo p{
        margin-bottom: 5px;
    }
    
    .azucane_logo img{
        background-color: #fff;
        width: 50px;
        height: auto;
    }

    .policies p, a {
        color: #fff !important;
        margin-bottom: 5px;
    }
    
    .policies ul li a {
        color: #fff !important;
    }
    
    .policies ul li a {
        color: #fff !important;
        margin: 3px 10px;
        display: inline-block;
    }

    .menuItem.dropDown.pcly ~ .links {
        height: 0 !important;
    }

    .pcly.active.menuItem ~ .links {
        height: 195px !important;
    }

    .flw{
        color: #fff !important;
    }

    .mobMenu .menuItem {
        padding: 5px 0;
        color: #fff;
        margin-left: 0;
    }
}