.industrial.mytext {
    background-image: linear-gradient(to bottom, #0086bb 30%, #538b34);
    color: transparent;
    background-clip: text;
    font-size: 35px;
}

.industry.headings {
    width: 77%;
    margin: auto;
}

.industry p {
    color: #000;
}

/* industry specific services section */

.indSrvc {
    background-color: #e5dfd9;
}

.indcards {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    margin: 20px auto;
    padding: 15px;
    width: 97%;
}

.indcards img {
    background: #eb932f;
    border-radius: 50%;
    padding: 9px;
    width: 75px;
    height: 75px;
}

.indcards p {
    margin-bottom: 0;
}

.indcards h5 {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 2px;
}

.staff {
    margin: 20px auto !important;
}

.rghtSect .lady {
    width: 100%;
    position: relative;
    left: 25px;
}

.staffing.py-5 .sm-title {
    position: relative;
    left: 5%;
    margin-top: 20px;
    font-weight: 600;
    font-size: 23px;
}

.smt2 {
    width: fit-content;
    left: 55% !important;
}

.clients.py-5 .text-center img {
    width: 150px !important;
    height: auto;
}

.staffing.py-5 .sm-title:nth-child(1) {
    margin-bottom: 0;
}

.cards {
    display: flex;
    flex-wrap: wrap;
}

.staffing {
    background: url('./img/staffingBG.webp') center/cover no-repeat;
}

.staff {
    width: 40%;
    margin: 20px 55px;
    background-color: #fff;
}

.staff img {
    width: 100%;
}

.innertexts {
    margin: 15px;
}

.staff h5 {
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 4px;
}

.indstats {
    /* background: url('./img/skillsBG.webp') center/cover no-repeat; */
    position: relative;
    height: 400px;
}

.indRows {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    color: #fff;
    padding: 48px 0;
}

.line {
    width: 2px !important;
    height: 95px;
    position: relative !important;
    background-color: #fff;
}

.indRows p {
    font-size: 20px;
    width: fit-content;
    line-height: 1.2;
    position: relative;
}

.indRows p:after {
    /* content: ""; */
    display: inline-block;
    width: 2px;
    height: 154%;
    position: absolute;
    background-color: #fff;
    right: -33%;
    top: -8px;
}

.indRows .nobr:after {
    display: none;
}

span.boldText {
    font-weight: bold;
    font-size: 32px;
}