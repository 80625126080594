.joinSect .content {
    color: #000;
    font-size: 30px;
}

.valuesSect {
    display: flex;
    justify-content: center;
}

/* .join {
    background-image: url('./img/joinus.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 300px;
    color: #fff;
    text-align: center;
    justify-content: center;
} */

.form {
    background-image: url('./img/Joinusform.webp');
    height: 740px;
    background-repeat: no-repeat;
    background-size: cover;
}

.joinUs .recaptcha{
    margin: 5px auto;
}

.contact .recaptcha{
    margin: 10px auto;
}

.contact .submt{
    display: block;
    width: 100%;
}

.headline-text span {
    font-style: italic;
}

/* Accordion */

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: none;
}

.faq {
    background-color: #d1c9c1;
}

.accordion {
    --bs-accordion-color: var(--bs-body-color);
    --bs-accordion-bg: unset;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
}

.accordion-collapse.collapse.show {
    background-color: #fff !important;
}

.accordion-button {
    color: #000;
    font-weight: 500;
    font-size: 18px;
}

.accordion-item {
    border: none;
    border-bottom: 1px solid #f2f0ed;
    border-radius: 0 !important;
}

.accordion {
    --bs-accordion-border-radius: 0 !important;
}

.joinUs {
    display: flex;
    flex-direction: column;

}

.joinUs .inpt {
    width: 50%;
    margin: 5px auto;
    padding: 10px;

}

.joinUs .fileUpload {
    position: relative;
    padding: 20px;
}

.fileUpload span {
    position: absolute;
    left: 1.5%;
    top: 5px;
    color: #000000b3;
}

.fileUpload span .file {
    padding-left: 5px;
}

.formContainer {
    background-color: #ffffff78;
}

.inpt::placeholder {
    color: #000000b3;
}

.fileUpload {
    border: 1px solid #000;
    background: #fff;
    margin-left: 0;
}

.submitBtn {
    width: fit-content;
    padding: 7px 32px;
    background-color: #000;
    color: #ffffff;
    font-weight: 500;
    border: none;
    margin: 8px auto;
    text-align: center;
}

.userSect .submitBtn {
    margin: 8px 116px;
}

/* Connect with us page */

.presence {
    display: flex;
    justify-content: center;
}

.presence .block2 {
    margin-left: 50px;
}

.presence .block .title {
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

.presence .block p {
    color: #000;
}

.presence .block a {
    color: #000 !important;
    text-decoration: none !important;
}

.presence .block a:hover {
    color: #0d6efd !important;
    text-decoration: underline !important;
}

.presence .block span {
    font-weight: 600;
}

.connectForm {
    background-color: #f4ece3;
    background-image: url('./img/get_in_touch.webp');
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
}

.connectForm label {
    display: block;
}

.connectForm .inptBlock {
    width: 43%;
    display: inline-block;
    margin-bottom: 8px;
}

.connectForm .inptBlock .inpt,
.PhoneInput input {
    width: 95%;
    padding: 5px;
}

.connectForm .inptBlock select.inpt {
    width: 95%;
    padding: 5px;
    border: 1px solid #000;
}

.connectForm .inptBlock select.inpt:focus option:first-of-type {
    display: none;
}

.textField {
    width: 86% !important;
}

.fileUploadBlck {
    border: 1px solid #000 !important;
    background-color: #fff;
    padding: 5px;
}

.connectForm .container .upperFrmSect {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: center;
}

.connectForm .container {
    margin: 0 auto;
    max-width: 900px;
}

.PhoneInputCountry {
    position: absolute !important;
    top: 10px;
    left: 6px;
}

.PhoneInput {
    display: flex;
    align-items: center;
    position: relative;
    width: 95%
}

.joinUs .PhoneInput{
    width: 50%;
    margin: 5px auto;
}

.joinUs .PhoneInput input{
    padding: 10px;
}

.joinUs .PhoneInputCountry{
    padding-top: 5px;
    padding-left: 5px;
}

input.PhoneInputInput {
    padding-left: 46px !important;
    display: inline-block;
}

.values .container p:nth-child(2) {
    margin: auto 54px;
}

.userSect,
.employee {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #ef8f2287;
}

.employee {
    margin: 0px 60px;
    padding: 10px 0;
    background-color: #faefe2;
}