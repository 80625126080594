/* font-family: 'Open Sans', sans-serif;
 font-family: 'Roboto', sans-serif;
 */
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {}

/*---------BEGIN : app timeline ------------*/
.app-timeline-wrapper {
    overflo-x: auto;
    overflow-y: hidden;
}

.app-timeline {
    --app-timeline-dotsize: 15px;
    --app-timeline-dot-left-margin: 80px;
    --app-timeline-primary-color: #35858b;
    --app-timeline-line-color: #c8c8c8;
    --app-timeline-box-width: 321px;
    --app-timeline-from-center-dist: 165px;
    --app-timeline-box-gap: 10px;
    position: relative;
    height: 420px;
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: var(--app-timeline-box-gap);
    min-width: max-content;
}

.app-timeline-display-line {
    position: absolute;
    height: 2px;
    background-color: var(--app-timeline-line-color);
    width: 100%;
    left: 0px;
    top: 175px;
}

.app-timeline-display-line:after {
    right: 0px;
}

.app-timeline-card-wrapper {
    --timeline-tail-arrow-bot-align: -1;
    --timeline-border-color: transparent transparent var(--app-timeline-primary-color) transparent;
    --app-timeline-box-dot-order: 0;
    --app-timeline-box-tail-arrow-dist: calc(var(--app-timeline-dotsize) * var(--timeline-tail-arrow-bot-align));
    position: relative;
    top: var(--app-timeline-from-center-dist);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 70px;
    width: 184px;
}

.app-timeline-dot {
    position: relative;
    content: "";
    width: 20px;
    height: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--app-timeline-primary-color);
    margin-left: var(--app-timeline-dot-left-margin);
    order: var(--app-timeline-box-dot-order);
}

.app-time-line-card {
    position: relative;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    transition: 150ms;
    text-align: center;
}

.app-timeline-card-align-top .app-time-line-card:before {
    display: block;
    position: absolute;
    top: -57px;
    border-width: calc(var(--app-timeline-dotsize) / 2);
    border-color: var(--timeline-border-color);
    left: 88px;
    width: 1px;
    height: 58px;
    background: #88c011;
}

span.app-timeline-dot::after {
    content: "";
    display: inline-block;
    width: 32px;
    height: 32px;
    position: absolute;
    top: -26px;
    left: -26px;
    border: 1px solid #665a5a;
    border-radius: 50%;
    margin: 20px;
    animation: circleZoom 2s infinite;
}

@keyframes circleZoom {
    0% {
        scale: 100%;
    }

    50% {
        scale: 150%;
    }

    100% {
        scale: 100%;
    }
}

.bottom2:before{
    content: "2020" !important;
}

.top2:before{
    content: "2021" !important;
}

.bottom3:before{
    content: "2022" !important;
}

.top3:before{
    content: "2023" !important;
}

.bottom4:before{
    content: "2024" !important;
}

span.app-timeline-dot::before {
    content: "";
    display: inline-block;
    width: 48px;
    height: 48px;
    position: absolute;
    top: -34px;
    left: -34px;
    border: 1px solid #665a5a;
    border-radius: 50%;
    margin: 20px;
    

}

@keyframes rotateIt{
    100%{
        transform: scale(1.1)
    }
}

.app-time-line-card:before {
    content: "";
    position: absolute;
    top: -57px;
    border-width: calc(var(--app-timeline-dotsize) / 2);
    border-color: var(--timeline-border-color);
    left: 88px;
    width: 1px;
    height: 58px;
    background: #88c011;
}

.app-timeline-card-align-bottom .app-time-line-card:after {
    content: "";
    position: absolute;
    top: 0px;
    border-width: calc(var(--app-timeline-dotsize) / 2);
    border-color: var(--timeline-border-color);
    left: 85px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #88c011;
}

.app-time-line-card .app-timeline-date {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    margin-bottom: 0;
}

.app-time-line-card .app-timeline-text {
    font-size: 14px;
    margin-bottom: 0;
    color: #000;
}

.app-timeline-card-wrapper.app-timeline-card-align-top {
    --timeline-border-color: var(--app-timeline-primary-color) transparent transparent transparent;
    --app-timeline-box-dot-order: 1;
    top: -56%;
    bottom: var(--app-timeline-from-center-dist);
    align-self: flex-end;
}

.app-timeline-card-wrapper.app-timeline-card-align-top .app-time-line-card:before {
    display: block;
    position: absolute;
    top: 68px;
    border-width: calc(var(--app-timeline-dotsize) / 2);
    border-color: var(--timeline-border-color);
    left: 89px;
    width: 1px;
    height: 58px;
    background: #88c011;
}

.app-timeline-card-wrapper.app-timeline-card-align-top .app-time-line-card:after {
    content: "";
    position: absolute;
    top: 62px;
    border-width: calc(var(--app-timeline-dotsize) / 2);
    border-color: var(--timeline-border-color);
    left: 86.4px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #88c011;
}

.app-timeline-card-wrapper.app-timeline-green-box {
    --app-timeline-primary-color: #7fbb00;
}

.app-timeline-card-wrapper.app-timeline-blue-box {
    --app-timeline-primary-color: #1ea9e1;
}

.app-timeline-card-wrapper.app-timeline-yellow-box {
    --app-timeline-primary-color: rgba(255, 199, 0, 1);
}

.top1 .app-time-line-card:before {
    top: 149px !important;
    background: #50a6df !important
}

.top1 .app-time-line-card:after {
    top: 149px !important;
    background: #50a6df !important
}

.top2 .app-time-line-card:before {
    top: 58px !important;
    background: #50a6df !important
}

.top2 .app-time-line-card:after {
    top: 58px !important;
    background: #50a6df !important
}

.top3 .app-time-line-card:before {
    top: 58px !important;
    background: #50a6df !important
}

.top3 .app-time-line-card:after {
    top: 58px !important;
    background: #50a6df !important
}


/*---------END : app timeline ------------*/
/*---------BEGIN : KJV logo ------------*/
.kjv-logo-link {
    position: fixed;
    right: 10px;
    bottom: 10px;
}

.kjv-logo {
    --kjvBgColor: #000;
    --kjvFgColor: #fff;
    --kjvTransition: 300ms;
    --kjvLogoSize: 70px;
    height: var(--kjvLogoSize);
    width: var(--kjvLogoSize);
    transition: var(--kjvTransition);
    transform: rotate(-180deg);
}

.kjv-logo path,
.kjv-logo g,
.kjv-logo rect {
    transform-box: fill-box;
    transform-origin: center;
}

#kjvOuterFill {
    fill: var(--kjvBgColor);
}

#kjvTringleLeft,
#kjvTringleRight {
    stroke: var(--kjvFgColor);
    fill: var(--kjvFgColor);
}

#kjvLetters path {
    stroke: var(--kjvFgColor);
    fill: var(--kjvBgColor);
}

#kjvTringleRight {
    transform: translateX(-347px);
    transition: var(--kjvTransition);
}

#kjvTringleLeft {
    transform: translateX(347px);
    transition: var(--kjvTransition);
}

#kjvLetters {
    transform: scale(0);
    transition: var(--kjvTransition);
}

.kjv-logo-link:hover .kjv-logo {
    transform: rotate(0deg);
}

.kjv-logo-link:hover #kjvTringleRight,
.kjv-logo-link:hover #kjvTringleLeft {
    transition-delay: var(--kjvTransition);
    transform: translateX(0px);
}

.kjv-logo-link:hover #kjvLetters {
    transition-delay: calc(var(--kjvTransition) * 2);
    transform: scale(1);
}

/*---------END : KJV logo ------------*/

.app-timeline-card-align-bottom:before {
    content: "2017";
    display: inline-block;
    position: absolute;
    top: -60px;
    left: 33%;
    font-size: 25px;
    font-family: "Figtree", serif;
    font-weight: 500;
}

.app-timeline-card-align-top:before {
    content: "2019";
    display: inline-block;
    position: absolute;
    bottom: -60px;
    left: 33%;
    font-size: 25px;
    font-family: "Figtree", serif;
    font-weight: 500;
}